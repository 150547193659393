import clsx from "clsx";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

type FinalButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithChildren;

export function Button(props: FinalButtonProps) {
  return (
    <button
      {...props}
      className={clsx(
        "flex w-full cursor-pointer justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white",
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
        "hover:bg-indigo-500",
        "disabled:cursor-not-allowed disabled:bg-indigo-300 disabled:hover:bg-indigo-200",
        props.className,
      )}
    >
      {props.children}
    </button>
  );
}
