import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: Component,
});

function Component() {
  const navigate = useNavigate();

  navigate({ to: "/dashboard" });
}
