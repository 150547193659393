import { PropsWithChildren } from "react";

type CardProps = PropsWithChildren & {
  className?: string;
};

export function Card(props: CardProps) {
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <div className={props.className}>{props.children}</div>
      </div>
    </div>
  );
}
