import { Schema } from "@effect/schema";

const buildResponseSchema = <A, I, R>({ data }: { data: Schema.Schema<A, I, R> }) =>
  Schema.Struct({
    data: data,
    errors: Schema.Null,
    meta: Schema.Struct({}),
    status: Schema.Number,
  });

const DashboardSchema = Schema.Struct({
  user: Schema.Struct({
    id: Schema.String,
    display: Schema.String,
  }),
});

export type DashboardData = typeof DashboardSchema.Type;

const DashboardResponseSchema = buildResponseSchema({
  data: DashboardSchema,
});

export const dashboardResponseDecoder = Schema.decode(DashboardResponseSchema);
