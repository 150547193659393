import { createFileRoute } from "@tanstack/react-router";
import { Effect } from "effect";

import { API } from "../../api";
import { standardErrorMessages } from "../../utils/fetch";
import { Card } from "../../components/card";

export const Route = createFileRoute("/_auth/dashboard")({
  component: Component,

  /**
   * Note the use of `runPromise` as opposed to `runPromiseExit`. This is so that any errors
   * when running the promise will be bubbled up to to components error handler and an error page
   * is shown. We map all the errors to a simple and friendly error message.
   */
  loader: () => Effect.runPromise(API.dashboard().pipe(Effect.mapError(standardErrorMessages))),
});

function Component() {
  const data = Route.useLoaderData();

  return (
    <Card>
      <h1>Hello {data.data.user.display}!</h1>
    </Card>
  );
}
