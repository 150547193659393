/* eslint-disable react/no-children-prop */
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useForm } from "@tanstack/react-form";
import { Effect, Record } from "effect";
import { useState } from "react";

import { Input } from "../../components/input";
import { Card } from "../../components/card";
import { API } from "../../api";
import { Button } from "../../components/button";
import { AllKnownRoutes, allKnownRoutes } from "../../main";
import { toApplicationSession } from "../../auth";

type SigninSearch = {
  next: AllKnownRoutes;
};

export const Route = createFileRoute("/_public/signin")({
  validateSearch: (search: Record<string, unknown>): SigninSearch => {
    const matchedRoute = allKnownRoutes.find((elem) => elem == search.next) as AllKnownRoutes;

    return {
      next: matchedRoute || "/dashboard",
    };
  },
  component: Component,
  beforeLoad: ({ context, search }) => {
    if (context.session.isAuthenticated) {
      return redirect({ to: search.next });
    }
  },
});

function Header() {
  return (
    <div>
      <h1 className="text-center text-xl leading-9">Digital Collections</h1>
      <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>
  );
}

function SigninForm() {
  const [globalError, setGlobalError] = useState<string[]>([]);
  const { setSession } = Route.useRouteContext();
  const navigate = Route.useNavigate();
  const search = Route.useSearch();

  const form = useForm({
    onSubmit: async ({ value, formApi }) => {
      const updateErrorState = ({
        param,
        message,
      }: {
        param?: string | undefined;
        message: string;
      }) => {
        // NOTE(NZ): This was simplest way to get the type checking to be happy. I don't
        // love the switch but ¯\_(ツ)_/¯
        switch (param) {
          case "email":
          case "password":
            formApi.setFieldMeta(param, (prev) => ({
              ...prev,
              errorMap: { onChange: message },
            }));
            break;

          // NOTE(NZ): If someone tries spamming the login page it will hand back a response with no
          // `params` key. We can treat that as a global message
          default:
            setGlobalError(() => {
              return ["The form submission failed. Try again?"];
            });
            break;
        }
      };

      await Effect.runPromiseExit(
        API.signin(value.email, value.password).pipe(
          Effect.match({
            onSuccess: (value) => {
              const session = toApplicationSession(value);
              setSession(session);
              navigate({ to: search.next });
            },
            onFailure: (failure) => {
              if (failure._tag == "APIError") {
                failure.payload.errors.forEach(updateErrorState);
              } else {
                console.error(failure);
                setGlobalError((curr) => {
                  curr.push("The form submission failed. Try again?");
                  return curr;
                });
              }
            },
          }),
        ),
      );
    },
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <form
      className="space-y-6"
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await form.handleSubmit();
      }}
    >
      <div data-testid="fields" className="space-y-6">
        {globalError}
        <form.Field
          name="email"
          validators={{
            onBlur: ({ value }) => {
              if (!value.includes("@")) return "Not a valid email address";
            },
          }}
          children={(field) => (
            <Input
              id="email-address"
              name="email-address"
              type="email"
              label="Email Address"
              placeholder="Email Address"
              autoComplete="email"
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              error={field.state.meta.errors.join(" ")}
              required
            />
          )}
        />
        <form.Field
          name="password"
          validators={{
            onBlur: (value) => {
              if (!value.value) return "You must enter a password";
            },
          }}
          children={(field) => (
            <Input
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              autoComplete="current-password"
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              required
              error={field.state.meta.errors.join(" ")}
            />
          )}
        />
      </div>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <Button
            disabled={!canSubmit}
            title={canSubmit ? "Sign In" : "Correct form errors to sign in"}
          >
            {isSubmitting ? "..." : "Submit"}
          </Button>
        )}
      />
    </form>
  );
}

function Component() {
  return (
    <Card className="space-y-6">
      <Header />
      <SigninForm />
    </Card>
  );
}
