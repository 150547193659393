import { Link, Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { PropsWithChildren } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";

import { Card } from "../components/card";
import { AllKnownRoutes } from "../main";
import { User } from "../auth";

export const Route = createFileRoute("/_auth")({
  errorComponent: ({ error }) => {
    return (
      <>
        <Navigation />
        <Page>
          <Card className="space-y-6">
            <h1 className="text-2xl font-bold">Uh Oh! There was a problem</h1>
            <div className="flex items-center">
              <ExclamationCircleIcon className="mr-2 size-5 text-red-600" />
              <p>{error.message}</p>
            </div>
          </Card>
        </Page>
      </>
    );
  },
  /**
   * Validates that a user is set and provides a convenience context variable `user: User`
   * for all downstream routes
   */
  beforeLoad: ({ context, location }): { user: User } => {
    const user = context.session.user;
    if (!context.session.isAuthenticated || !user) {
      throw redirect({
        to: "/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          // @ts-expect-error https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes#redirecting
          next: location.href,
        },
      });
    }

    return {
      user: user,
    };
  },

  component: Component,
});

function Navigation() {
  const navigation: {
    name: string;
    href: AllKnownRoutes;
    current: boolean;
  }[] = [{ name: "Dashboard", href: "/dashboard", current: false }];

  return (
    <header className="bg-white shadow-sm">
      <div className="mx-auto max-w-7xl p-4 sm:px-6 lg:px-8">
        <div className="flex">
          <h1 className="text-lg font-semibold leading-6 text-gray-900">Digital Collections</h1>
          <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                aria-current={item.current ? "page" : undefined}
                className={clsx(
                  item.current
                    ? "border-indigo-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                )}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
}

function Page(props: PropsWithChildren) {
  return (
    <main>
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">{props.children}</div>
    </main>
  );
}

function Component() {
  return (
    <>
      <Navigation />
      <Page>
        <Outlet />
      </Page>
    </>
  );
}
