import { RouterProvider } from "@tanstack/react-router";
import { StrictMode, useState } from "react";

import { ApplicationSession, defaultApplicationSession } from "./auth";
import { router } from "./main";

export interface RouterContext {
  session: ApplicationSession;
  setSession: (session: ApplicationSession) => void;
}

export function Application() {
  const [session, setSession] = useState<ApplicationSession>(defaultApplicationSession);

  const sessionSetter = (session: ApplicationSession) => {
    setSession(session);
    router.invalidate();
  };

  return (
    <StrictMode>
      <RouterProvider router={router} context={{ session, setSession: sessionSetter }} />
    </StrictMode>
  );
}
