import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import React, { Suspense } from "react";
import { Effect } from "effect";

import { API } from "../api";
import { ApplicationSession, defaultApplicationSession } from "../auth";
import { RouterContext } from "../app";

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );

function Root() {
  return (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  );
}

export const Route = createRootRouteWithContext<RouterContext>()({
  /**
   * NOTE(NZ): beforeLoad is called for every child route, be careful you don't put
   * resource intensive tasks here. Only things that absolutely have to exist before
   * the app can be served
   */
  beforeLoad: async ({ context }): Promise<{ session: ApplicationSession }> => {
    // Reuse the existing session if it isn't the default one
    if (!context.session.isDefault) {
      return {
        session: context.session,
      };
    }

    const program = API.getSession().pipe(
      // We alays want there to be a session. If there isn't return the default
      // (unauthenticated) session and capture the error
      Effect.catchAll(() => {
        return Effect.succeed(defaultApplicationSession);
      }),
    );

    return {
      session: await Effect.runPromise(program),
    };
  },
  component: Root,
});
