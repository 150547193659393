import { Effect, pipe } from "effect";

import { authenticatedSessionDecoder, toApplicationSession } from "./auth";
import {
  AUTH_BASE_URL,
  BACKEND_URL,
  BadCodeError,
  HttpMethod,
  getPayload,
  request,
  requireCode,
} from "./utils/fetch";
import { dashboardResponseDecoder } from "./schemas";

export const API = {
  /**
   * Return the session for the user. If the user doesn't have a session a default session is
   * returned with a blank user.
   */
  getSession: () =>
    pipe(
      request(HttpMethod.GET, `${AUTH_BASE_URL}/auth/session`),
      Effect.flatMap(requireCode(200)),
      Effect.flatMap(getPayload),
      Effect.flatMap(authenticatedSessionDecoder),
      Effect.flatMap((value) => Effect.succeed(toApplicationSession(value))),
    ),

  signin: (email: string, password: string) =>
    pipe(
      request(HttpMethod.POST, `${AUTH_BASE_URL}/auth/login`, {
        email: email,
        password: password,
      }),
      Effect.flatMap(requireCode(200)),
      Effect.catchTag("BadCodeError", (error: BadCodeError) => error.toAPIError()),
      Effect.flatMap(getPayload),
      Effect.flatMap(authenticatedSessionDecoder),
    ),

  signup: (email: string, password: string) =>
    pipe(
      request(HttpMethod.POST, `${AUTH_BASE_URL}/auth/signup`, {
        email: email,
        password: password,
      }),
      Effect.flatMap(requireCode(200)),
      Effect.catchTag("BadCodeError", (error: BadCodeError) => error.toAPIError()),
      Effect.flatMap(getPayload),
    ),

  dashboard: () =>
    pipe(
      request(HttpMethod.GET, `${BACKEND_URL}/api/dashboard`),
      Effect.flatMap(requireCode(200)),
      Effect.catchTag("BadCodeError", (error: BadCodeError) => error.toAPIError()),
      Effect.flatMap(getPayload),
      Effect.flatMap(dashboardResponseDecoder),
    ),
};
