import { ExclamationCircleIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import { InputHTMLAttributes } from "react";

interface InputProps {
  id: string;
  label: string;
  error?: string;
}

type AllInputProps = InputProps & InputHTMLAttributes<HTMLInputElement>;

export function Input({ label, error, ...props }: AllInputProps) {
  return (
    <div>
      <label
        className={clsx("block text-sm font-medium leading-6 text-gray-900")}
        htmlFor={props.id}
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          {...props}
          aria-invalid={error ? "true" : "false"}
          aria-describedby={`${props.id}-error`}
          className={clsx(
            "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6",
            error
              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-600"
              : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600",
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {error && (
            <ExclamationCircleIcon
              aria-hidden="true"
              className="size-5 text-red-500"
            />
          )}
        </div>
      </div>
      {error && (
        <p
          id={`${props.id}-error`}
          className="mt-2 text-sm text-red-600"
        >
          {error}
        </p>
      )}
    </div>
  );
}
