import { Schema } from "@effect/schema";
import { ParseError } from "@effect/schema/ParseResult";
import { Effect, pipe } from "effect";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const AUTH_BASE_URL = `${BACKEND_URL}/api/browser/v1`;

interface ErrorType {
  _tag: string;
}

/**
 * Return a cookie if it is available from the browser otherwise null
 */
export function getCookie(name: string): string | null {
  let resp: string | null = null;
  if (!document.cookie || document.cookie === "") return resp;

  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.substring(0, name.length + 1) !== name + "=") continue;

    resp = decodeURIComponent(cookie.substring(name.length + 1));
    break;
  }

  return resp;
}

export class FetchError {
  readonly original: unknown;
  readonly _tag = "FetchError";

  constructor(err: unknown) {
    this.original = err;
  }
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
}

/**
 * Determins if the HTTP Method requires a CSRF token
 */
function requiresCSRF(method: HttpMethod) {
  switch (method) {
    case HttpMethod.GET:
      return false;
    default:
      return true;
  }
}

export class APIError {
  readonly original: ErrorType | undefined;
  readonly payload: typeof ErrorSchema.Type;
  readonly _tag = "APIError";

  constructor(err: typeof ErrorSchema.Type, original?: ErrorType) {
    this.payload = err;
    this.original = original;
  }

  /**
   * Return a single comma seperated string of all .message members
   */
  combinedMessage(): string {
    return this.payload.errors.reduce((acc, e, index) => {
      return (index === 0 ? "" : ", ") + acc + e.message;
    }, "");
  }
}

export class BadCodeError {
  readonly expected: number;
  readonly response: Response;
  readonly _tag = "BadCodeError";

  constructor(resp: Response, expected: number) {
    this.expected = expected;
    this.response = resp;
  }

  toAPIError() {
    if (this.response.status == 409) {
      return Effect.fail(
        new APIError(
          {
            status: 409,
            errors: [
              {
                message: "There was a conflict when submitting your request",
                code: "conflict-on-request",
              },
            ],
          },
          this,
        ),
      );
    }
    return pipe(
      this.response,
      getPayload,
      Effect.tap(console.log),
      Effect.flatMap(errorDecoder),
      Effect.flatMap((apiError) => Effect.fail(new APIError(apiError))),
    );
  }
}

export class BadJSONError {
  readonly _tag = "BadJSONError";
  readonly original: unknown;

  constructor(err: unknown) {
    this.original = err;
  }
}

export const ErrorDetailSchema = Schema.Array(
  Schema.Struct({
    message: Schema.String,
    code: Schema.String,
    param: Schema.optional(Schema.String),
  }),
);

export type ErrorDetail = typeof ErrorDetailSchema.Type;

export const ErrorSchema = Schema.Struct({
  status: Schema.optional(Schema.Number),
  errors: ErrorDetailSchema,
});

const errorDecoder = Schema.decode(ErrorSchema);

/**
 * Returns a function that will evaluate a response return code and fail if it doesn't match
 *
 * Usage:
 */
export const requireCode = (code: number) => (resp: Response) =>
  Effect.if(resp.status == code, {
    onTrue: () => Effect.succeed(resp),
    onFalse: () => Effect.fail(new BadCodeError(resp, code)),
  });

/**
 * Try to extract the JSON payload from a request or fail
 */
export const getPayload = (resp: Response) =>
  Effect.tryPromise({ try: () => resp.json(), catch: (err) => new BadJSONError(err) });

type DeclaredErrors = BadJSONError | FetchError | APIError | ParseError;

export const standardErrorMessages = (error: DeclaredErrors) => {
  switch (error._tag) {
    case "BadJSONError":
      return new Error("Unable to understand the server response. Try again.");
    case "FetchError":
      return new Error("The server might be unreachable. Try again.");
    case "APIError":
      return new Error(error.combinedMessage());
    case "ParseError":
      if (import.meta.env.DEV) {
        console.error(error);
      }
      return new Error(
        "The data from the server was malformed. Are your search parameters correct?",
      );
  }
};

/**
 * Complete a fetch request to the backend
 */
export function request(method: HttpMethod, url: string, data?: unknown) {
  const webHost = new URL(document.documentURI).host;
  const apiHost = new URL(url).host;
  const shoudIncludeCredentials = apiHost != webHost;

  const headers = new Headers();

  const options: RequestInit = {
    method,
    // We manually handle all redirects. For example if this returns a 302 which gets handed
    // back to a loader the loader will do the redirect.
    redirect: "manual",
  };

  if (shoudIncludeCredentials) options.credentials = "include";

  const csrfToken = getCookie("csrftoken");

  if (
    csrfToken
    && url !== `${AUTH_BASE_URL}/config`
    && url.startsWith(BACKEND_URL)
    && requiresCSRF(method)
  ) {
    headers.append("x-csrftoken", csrfToken);
  }

  if (data !== undefined) {
    options.body = JSON.stringify(data);
    headers.append("Content-Type", "application/json");
  }

  options.headers = headers;
  return Effect.tryPromise({ try: () => fetch(url, options), catch: (err) => new FetchError(err) });
}
