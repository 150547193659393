import "./index.css";

import { createRouter } from "@tanstack/react-router";
import { createRoot } from "react-dom/client";

import { routeTree } from "./routeTree.gen";
import { defaultApplicationSession } from "./auth";
import { Application } from "./app";

export const router = createRouter({
  routeTree,
  context: {
    session: defaultApplicationSession,
    setSession: undefined!,
  },
});

export const allKnownRoutes = Object.keys(router.routesByPath);
export type AllKnownRoutes = keyof typeof router.routesByPath;

export type AppRouter = typeof router;

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = createRoot(rootElement);

  root.render(<Application />);
}
